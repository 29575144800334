import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <h2 className="text-3xl">Hi from the about page</h2>
    <p>Could be about something...</p>
  </Layout>
)

export default SecondPage
